booking.addLoaderTo = function (element, preloaderClass) {
    var div = document.createElement('div');
    div.innerHTML = '<div id="overlay"><div class="w-100 d-flex justify-content-center align-items-center">' +
        '<div class="spinner"></div>' +
        '</div></div>';

    var el = document.querySelector(element);
    el.style.position = 'relative';

    el.appendChild(div.firstChild);
    document.querySelector('#overlay').style.display = 'flex';
};

booking.removeLoader = function (element) {
    var el = document.querySelector(element);

    if (el && el.parentNode) {
        el.parentNode.style.position = 'inherit';
    }

    var toRemoveEl = document.querySelector(element + ' #overlay');

    if (toRemoveEl) {
        toRemoveEl.remove();
    }
}
