require('./bootstrap');

feather.replace();

require('./plugins/datepicker.common.js');
require('./plugins/datepicker.js');
require('./plugins/datepicker.ro.js');

require('./plugins/preloader.js');

$(document).on("focus", ".datepicker", function () {
    $('[data-toggle="datepicker"]').datepicker({
        format: 'yyyy-mm-dd',
        autoHide: true
    });

    $('[data-toggle="datepicker"]').datepicker();
});

$('#sidebarMenu').on('shown.bs.collapse', function () {
    $('.table-bookings-users th').css('z-index', 0);
});

$('#sidebarMenu').on('hidden.bs.collapse', function () {
    $('.table-bookings-users th').css('z-index', 1060);
});

